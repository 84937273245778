<template>
  <v-main>
    <nav-bar/>
    <template slot:top>
      <v-subheader>
        <h1>Utilisateurs</h1>
      </v-subheader>
      <v-toolbar flat>
        <v-text-field
          class="pr-2 flex-grow-1"
          v-model="query"
          label="Mot clé"
          @input="search"
        />
        <v-select
          v-model="searchField"
          :items="searchFields"
          class="pl-2 flex-grow-0"
          dense
          solo
          @change="search"
        />
      </v-toolbar>
    </template>
    <v-data-table
      :headers="headers"
      :items="items"
      :item-class="itemRowBackground"
      :server-items-length="itemsLength"
      :options.sync="options"
      :must-sort="true"
      :footer-props="{'items-per-page-options':[50]}"
      dense
    >
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        target="_blank"
        @click="editItem(item.uid)"
      >
        mdi-pencil
      </v-icon>
    </template>
    </v-data-table>
  </v-main>
</template>

<script>
  import axios from 'axios'
  import NavBar from '../components/navBar.vue'

  export default {
    components:{
      NavBar,
    },
    data: () => ({
      headers: [
          { text: 'Id', value: 'id'},
          { text: 'Prénom', value: 'firstname'},
          { text: 'Nom', value: 'lastname'},
          { text: 'Téléphone', value: 'phone'},
          { text: 'Email', value: 'email'},
          { text: 'Date de création', value: 'creationDate' },
          { text: 'Admin', value: 'admin' },
          { text: 'Type', value: 'type'},
          { text: 'Code', value: 'code' },
          { text: 'Sponsor', value: 'sponsor', },
          { text: 'Premium', value: 'premium' },
          { text: 'Actions', value: 'actions', sortable: false },

      ],
      searchFields:[
        {text: 'Dans toutes les colonnes', value: 'id,type,firstname,lastname,phones,email,creationDate,admin'},
        { text: 'Id', value: 'id'},
        { text: 'Type', value: 'type' },
        { text: 'Prénom', value: 'firstname' },
        { text: 'Nom', value: 'lastname' },
        { text: 'Téléphone', value: 'phone' },
        { text: 'Email', value: 'email' },
        { text: 'Date de création', value: 'creationDate' },
        { text: 'Admin', value: 'admin' },
        { text: 'Code', value: 'code' },
        { text: 'Sponsor', value: 'sponsor' },
      ],
      searchField: 'id,type,firstname,lastname,phone,email,code,sponsor,creationDate,admin',
      items: [],
      itemsLength: 0,
      options: {
        sortBy: ['id'],
        sortDesc: [true]
      },
      query: '',
    }),

    watch: {
      options: {
        handler () {
          this.getItems()
        },
        deep: true
      },
    },


    methods: {
      itemRowBackground: function (item) {
        if(item.status === 3 )
        return 'style-status-ban'
      },
      getItems () {
        axios.get(process.env.VUE_APP_APIURL + '/users', {
          headers: {
            'Access-Token': localStorage.getItem('token')
          },
          params: {
            offset: (this.options.page - 1) * this.options.itemsPerPage,
            limit: this.options.itemsPerPage,
            sort: this.options.sortBy[0] + ' ' + (this.options.sortDesc[0] ? 'DESC' : 'ASC'),
            searchQuery: ((this.query !== '') ? this.query : undefined),
            searchFields: this.searchField
          }
        })

        
        .then(response => {
          console.log(response.data);
          this.items = response.data.map(item => Object.assign(item,
            {
              type: item.type == 1 ? '1 - propriétaire' :' 2 - locataire',
              creationDate: new Date(item.creationDate).toLocaleString(),
              phone: item.phone ? item.phone : "",
              premium: item.premium ? 'Oui' : 'Non',
            }
          ))
        })

        //Users Count
        axios.get(process.env.VUE_APP_APIURL + '/users', {
          headers: {
            'Access-Token': localStorage.getItem('token')
          },
          params: {
            count: 1,
            searchQuery: ((this.query !== '') ? this.query : undefined),
            searchFields: this.searchField
          }
        })
        .then(response => {
          this.itemsLength = eval(response.data[0].count)
        })
      },

      search () {
        this.options.page = 1
        this.getItems()
      },

      editItem (uid) {
        open(this.$router.resolve({name: 'user', params: {userUid: uid}}).href, '_blank');
      },
    }
  }
</script>

<style>
  .style-status-ban{
  color: #EF5350 !important;
  }
</style>
